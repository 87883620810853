<template>
  <div class="user_page">
    <content-title :title="$t('message.user')"></content-title>
    <div class="user_box">
      <div class="tabs">
        <div class="tab_title flexCenter-ai-center " v-if="amend_password_action == 0">
          <div class="title_item" :class="tab_action == 0 ? 'action' : '' " @click="tab_action = 0">
            {{$t('message.details')}}
          </div>
          <div class="title_item" :class="tab_action == 1 ? 'action' : '' " @click="tab_action = 1">
            {{$t('message.paymentPreferences')}}
          </div>
        </div>
        
        <!-- 合作信息 -->
        <div class="cooperation" v-if="tab_action == 0">
          <div class="cooperation_one" v-if="amend_password_action == 0">
            <div class="item flexCenter-ai-center">
              <p>{{$t('message.platform')}}</p>：
              <span>{{cooperation_data.distributor_name}}</span>
            </div>
            <div class="item flexCenter-ai-center">
              <p>{{$t('message.account')}}</p>：
              <span>{{cooperation_data.login_name}}</span>
            </div>
            <div class="item flexCenter-ai-center">
              <p>{{$t('message.password')}}</p>：
              <span>{{cooperation_data.login_password_default}}</span>
              <div class="btn button-me" @click="amend_password_action = 1">{{$t('message.resetYourPassword')}}</div>
            </div>
            <div class="item flexCenter-ai-center">
              <p>{{$t('message.commission')}}</p>：
              <span>{{cooperation_data.separate_rate}}</span>
            </div>
          </div>
          <!-- 密码表单 -->
          <div class="amend_password" v-else-if="amend_password_action == 1"
          v-loading="is_submitted">
            <el-form :model="password_rule_form"
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm">
              <el-form-item prop="old_password"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.currentPassword')}}:</span>
                <el-input v-model="password_rule_form.old_password"
                          show-password
                          :placeholder="$t('message.currentPassword')"
                          @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item>
              <el-form-item prop="new_password"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.newPassword')}}:</span>
                <el-input v-model="password_rule_form.new_password"
                          :placeholder="$t('message.APasswordShouldBeAtLeast8Characters')"
                          show-password
                          @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item>
              <el-form-item prop="affirm_password"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.confirmPassword')}}:</span>
                <el-input v-model="password_rule_form.affirm_password"
                          :placeholder="$t('message.confirmNewPassword')"
                          show-password
                          @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item>
              <!-- 登陆按钮 -->
              <el-form-item>
                <el-button plain
                          style="width: 130px;font-size: 15px;border-radius: 6px;"
                          @click="amend_password_action = 0"
                          >
                        {{$t('message.cancel')}}
                </el-button>
                <el-button type="primary"
                          style="width: 130px;font-size: 15px;margin-left: 24px;border-radius: 6px;"
                          @click="submitForm('ruleForm')">
                        {{$t('message.done')}}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <!-- 收款信息 -->
        <div class="proceeds_data" v-else-if="tab_action == 1">
          <!-- 收款表单 -->
          <div class="proceeds_form" v-if="proceeds_data_box == 1"
          v-loading="is_submitted">
            <div class="proceeds_form_title flexCenter-ai-center">
              <div class="title_item" 
              :class="proceeds_bank_alipay == 1 ? 'action' : '' " 
              @click="changeProceedsBankAlipay(1)"
              style="border-radius: 8px 0px 0px 8px;  border-right: 0px;">
                {{$t('message.bankTransfer')}}
              </div>
              <div class="title_item" 
              :class="proceeds_bank_alipay == 2 ? 'action' : '' " 
              @click="changeProceedsBankAlipay(2)"
              style="border-radius: 0px 8px 8px 0px;  border-left: 0px ;">
                {{$t('message.Alipay')}}
              </div>
            </div>
            <!-- 银行卡表单 -->
            <el-form :model="bank_card"
                    :rules="bank_rules"
                    ref="bank_card"
                    class="demo-ruleForm"
                    v-show="proceeds_bank_alipay == 1">
              <el-form-item prop="name"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.recipient')}}:</span>
                <el-input v-model="bank_card.name"
                          :placeholder="$t('message.recipent')"
                          @keyup.enter.native="submitForm('bank_card')"></el-input>
              </el-form-item>
              <el-form-item prop="number"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.cardNumber')}}:</span>
                <el-input v-model="bank_card.number"
                          :placeholder="$t('message.cardNumber')"
                          @keyup.enter.native="submitForm('bank_card')"></el-input>
              </el-form-item>
              <el-form-item prop="bank_name"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.bank')}}:</span>
                <el-input :placeholder="$t('message.eg')"
                          v-model="bank_card.bank_name"
                          @keyup.enter.native="submitForm('bank_card')"></el-input>
              </el-form-item>
              <!-- 登陆按钮 -->
              <el-form-item>
                <el-button plain
                          style="width: 130px;font-size: 15px;border-radius: 6px;margin-right: 24px;"
                          @click="proceeds_data_box = 0"
                          v-if="pay_data != ''"
                          >
                          {{$t('message.cancel')}}
                </el-button>
                <el-button type="primary"
                          style="width: 130px;font-size: 15px;border-radius: 6px;"
                          @click="bankSubmitForm('bank_card')">
                          {{$t('message.submit')}}
                </el-button>
              </el-form-item>
            </el-form>
            
            <!-- 支付表单 -->
            <el-form :model="action"
                    :rules="action_rules"
                    ref="action_card"
                    class="demo-ruleForm"
                    v-show="proceeds_bank_alipay == 2">
              <el-form-item prop="name"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.recipient')}}:</span>
                <el-input v-model="action.name"
                          :placeholder="$t('message.recipient')"
                          @keyup.enter.native="submitForm('action_card')"></el-input>
              </el-form-item>
              <el-form-item prop="number"
                            style="margin-bottom: 8px;">
                <span>{{$t('message.AlipayAccount')}}:</span>
                <el-input v-model="action.number"
                          :placeholder="$t('message.AlipayAccount')"
                          @keyup.enter.native="submitForm('action_card')"></el-input>
              </el-form-item>
              <!-- 登陆按钮 -->
              <el-form-item>
                <el-button plain
                          style="width: 130px;font-size: 15px;border-radius: 6px;margin-right: 24px;"
                          @click="proceeds_data_box = 0"
                          v-if="pay_data != ''"
                          >
                          {{$t('message.cancel')}}
                </el-button>
                <el-button type="primary"
                          style="width: 130px;font-size: 15px;border-radius: 6px;"
                          @click="actionSubmitForm('action_card')">
                          {{$t('message.submit')}}
                </el-button>
              </el-form-item>
            </el-form>
          </div>

          <!-- 收款信息 -->
          <div class="proceeds_data_big_box" v-else-if="proceeds_data_box == 0">

            <div class="proceeds_data_box">
              <div class="item flexCenter-ai-center">
                <p>{{$t('message.paymentMethod')}}</p>：
                <span>{{pay_data.pay_type_name}}</span>
              </div>
              <div class="item flexCenter-ai-center">
                <p>{{$t('message.recipient')}}</p>：
                <span>{{pay_data.account_name}}</span>
              </div>
              <div class="item flexCenter-ai-center">
                <p>{{$t('message.cardNumber')}}</p>：
                <span>{{pay_data.account}}</span>
              </div>
              <div class="item flexCenter-ai-center" v-if="pay_data.bank_name">
                <p>{{$t('message.bank')}}</p>：
                <span>{{pay_data.bank_name}}</span>
              </div>
              <div class="btn_text">
                <div class="btn" :class="prohibitClass" @click="editPayBtn">{{prohibitText}}</div>
                <div class="text" v-if="pay_data.remark != '' ">{{pay_data.remark}}</div>
              </div>
            </div>
            
            <div class="proceeds_data_box" v-if="success_pay_data != ''">
              <div class="min_title">
                {{$t('message.currentPaymentPreferences')}}
              </div>
              <div class="item flexCenter-ai-center">
                <p>{{$t('message.paymentMethod')}}</p>：
                <span>{{success_pay_data.pay_type_name}}</span>
              </div>
              <div class="item flexCenter-ai-center">
                <p>{{$t('message.recipient')}}</p>：
                <span>{{success_pay_data.account_name}}</span>
              </div>
              <div class="item flexCenter-ai-center">
                <p>{{$t('message.cardNumber')}}</p>：
                <span>{{success_pay_data.account}}</span>
              </div>
              <div class="item flexCenter-ai-center" v-if="success_pay_data.bank_name">
                <p>{{$t('message.bank')}}</p>：
                <span>{{success_pay_data.bank_name}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import user from './user.js'
export default user
</script>
<style lang="scss" scoped>
@import './user.scss';
</style>
<style lang="scss">
.user_box{
  .amend_password, .proceeds_form{
    .el-form-item {
      margin-top: 20px;
      .el-form-item__content {
        // display: flex;
        // align-items: center;
        >span {
          width: 160px;
          text-align: right;
          margin-right: 16px;
          font-size: 14px;
          color: #57595F;
          padding-left: 2px;
        }
        .el-input__inner {
          height: 46px;
          border-radius: 6px;
          border-color: #B8BAC1;
          color: #32343A;
        }
        .el-form-item__error {
          // left: 96px;
        }
      }
    }
    .el-form-item:nth-child(1) {
      margin-top: 0;
    }
    .el-form-item:last-child {
      .el-form-item__content{
        // justify-content: center;
        // margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
  .proceeds_form {
     .el-form-item {
       .el-form-item__content {
         span {
            text-align-last: justify;
         }
       }
     }
    .el-form-item:nth-child(1) {
      margin-top: 30px;
    }
  }
}
</style>
