
import contentTitle from '@/components/contentTitle/contentTitle'//顶部栏
import { user, rspwd, getPay, dealPay } from '@/apis/api'
export default {
  data () {
    // 旧密码校验
    var old_password = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('message.currentPassword')));
      } else if(!this.is_old_password) {
        return callback(new Error(this.$t('message.passwordIncorrect')));
      } {
        return callback();
      }
    };
    // 新密码校验
    var new_password = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('message.pleaseEnterNewPassword')));
      } else if (this.password_rule_form.affirm_password != '') {
        this.$refs.ruleForm.validateField('affirm_password')
        return callback();
      }
      return callback();
    };
    // 确认密码校验
    var affirm_password = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('message.confirmNewPassword')));
      } else if (this.password_rule_form.new_password !== value) {
        return callback(new Error(this.$t('message.PleaseMakeSureYourPasswordsMatch')));
      }
      return callback();
    };
    return {
      tab_action: 0, // tab 状态
      amend_password_action: 0, // 0 为不显示 修改密码状态
      proceeds_data_box: 0, // 0 为显示收款信息 1 为收款表单
      proceeds_bank_alipay: 1, // 1为银行 2为支付宝 收款输入框
      is_submitted: false, // 提交 加载状态
      // 密码表单数据
      password_rule_form: {
        // 原密码
        old_password: '',
        // 新密码
        new_password: '',
        // 确认密码
        affirm_password: ''
      },
      is_old_password: true, // 原密码是否相同 true 为相同 false 为不相同
      // 密码表单验证
      rules: {
        old_password: [
          // 1. 请输入原密码
          // 2. 原密码错误, 请重试
          { validator: old_password, trigger: 'change'},
          {min: 8, max: 50, message: this.$t('message.APasswordShouldBeAtLeast8Characters'), rigger: 'blur' }
        ],
        new_password: [
          // 1. 为空提示 请输入新密码
          { validator: new_password, trigger: 'change' },
          {min: 8, max: 50, message: this.$t('message.APasswordShouldBeAtLeast8Characters'), rigger: 'blur' }
        ],
        affirm_password: [
          // 1. 请输入确认密码
          // 2. 新密码 与 确认密码不一致, 请重试
          { validator: affirm_password, trigger: 'blur' },
          // {min: 8, max: 50, message: '密码不足8位', rigger: 'change' }
        ]
      },
      // 银行卡数据
      bank_card: {
        name: '', // 户名
        number: '', // 卡号
        bank_name: '', // 银行全称
      },
      bank_rules: {
        name: [
          { required: true, message: this.$t('message.recipent'), trigger: 'change' },
          // 长度在 1 到 50 个字符
          {min: 1, max: 50, message: this.$t('message.recipent'), rigger: 'blur' }
        ],
        number: [
          { required: true, message: this.$t('message.cardNumber'), trigger: 'change' },
          // 长度在 1 到 50 个字符
          {min: 6, max: 50, message: this.$t('message.cardNumber'), rigger: 'blur' }
        ],
        bank_name: [
          // 长度在 1 到 100 个字符
          { required: true, message: this.$t('message.pleaseEnterTheFullNameOfTheBank'), trigger: 'change' },
          {min: 1, max: 100, message: this.$t('message.pleaseEnterTheFullNameOfTheBank'), rigger: 'blur' }
        ]
      },

      // 支付宝数据
      action: {
        name: '', // 收款人
        number: '', // 支付宝号
      },
      action_rules: {
        name: [
          { required: true, message: this.$t('message.recipient'), trigger: 'change' },
          // 长度在 1 到 50 个字符
          {min: 1, max: 50, message: this.$t('message.recipient'), rigger: 'change' }
        ],
        number: [
          { required: true, message: this.$t('message.AlipayAccount'), trigger: 'change' },
          // 长度在 1 到 50 个字符 required: true, 
          {min: 2, max: 50, message: this.$t('message.AlipayAccount'), rigger: 'change' }
        ],
      },

      // 合作信息
      cooperation_data: {
        distributor_name: '', // 渠道商名称
        email: '', // 邮箱
        login_name: '', // 账号
        login_password_default: '', // 加码密码
        separate_rate: '', // 比例
      },
      // 收款信息
      pay_data: {
        pay_type: '', // 	支付方式 1银行卡 2支付宝
        pay_type_name: '', // 支付方式名称 银行卡
        account: '', // 收款账号
        account_name: '', // 户名 , 示例 “G4nRDFbC”
        bank_name: '', //银行名称， 示例 招商银行
        verify_status: '', // 0 编辑； 1 审核中； 2 重新编辑
        remark: '', // 备注 示例审核情况信息， 审核不通过，银行卡错误…
      },
      success_pay_data: {
        pay_type: '', // 	支付方式 1银行卡 2支付宝
        pay_type_name: '', // 支付方式名称 银行卡
        account: '', // 收款账号
        account_name: '', // 户名 , 示例 “G4nRDFbC”
        bank_name: '', //银行名称， 示例 招商银行
        verify_status: '', // 0 编辑； 1 审核中； 2 重新编辑
        remark: '', // 备注 示例审核情况信息， 审核不通过，银行卡错误…
      }
    }
  },
  created () {
    this.getUser()
    this.getPay()
  },
  mounted () {
  },
  methods: {
    // 重置密码校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.resetPassword()
        }
      })
    },
    // 收款表达的切换
    changeProceedsBankAlipay (type) {
      this.proceeds_bank_alipay = type
      this.$refs.bank_card.clearValidate(this.bank_rules)
      this.$refs.action_card.clearValidate(this.bank_rules)
    },
    // 银行表单的校验
    bankSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            pay_type: this.proceeds_bank_alipay,
            account: this.bank_card.number,
            account_name: this.bank_card.name,
            bank_name: this.bank_card.bank_name,
          }
          this.detailPay(params)
        } else {
        }
      })
    },
    // 支付宝表单的校验
    actionSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            pay_type: this.proceeds_bank_alipay,
            account: this.action.number,
            account_name: this.action.name,
            // bank_name: this.proceeds_bank_alipay === 1 ? this.bank_card.bank_name : this.action.name,
          }
          this.detailPay(params)
        } else {
        }
      })
    },
    // 进入编辑页按钮
    editPayBtn() {
      if (this.pay_data.verify_status != 1) {
        this.proceeds_data_box = 1
        this.proceeds_bank_alipay = this.pay_data.pay_type
        if (this.proceeds_bank_alipay == 1) {
          this.bank_card.number = this.pay_data.account
          this.bank_card.name = this.pay_data.account_name
          this.bank_card.bank_name = this.pay_data.bank_name
        } else if (this.proceeds_bank_alipay == 2) {
          this.action.number = this.pay_data.account
          this.action.name = this.pay_data.account_name
        }
      }
    },
    // 获取合作信息接口
    async getUser () {
      let res = await user()
      if (res.code === 1) {
        this.cooperation_data = res.data
      }
    },
    // 重置密码
    async resetPassword() {
      this.is_submitted = true
      let params = {
        password: this.password_rule_form.new_password,
        repassword: this.password_rule_form.affirm_password,
        origin_password: this.password_rule_form.old_password
      }
      let res = await rspwd(params)
      this.is_submitted = false
      if (res.code === 1) {
        this.$message({
          message: this.$t('message.passwordUpdated'),
          type: 'success'
        })
        this.amend_password_action = 0
      } else if (res.code === 1012) {
        this.is_old_password = false
        this.$refs.ruleForm.validateField('old_password')
        this.is_old_password = true
      }
    },
    // 获取收款信息
    async getPay() {
      let res = await getPay()
      if (res.code === 1) {
        if (res.data === '') {
          this.proceeds_data_box = 1
        } else {
          this.pay_data = res.data.verify_data
          this.success_pay_data = res.data.success_data
        }
      } else if (res.code === 0) {
        this.proceeds_data_box = 1
        this.pay_data = ''
      }
      console.log(res)
    },
    // 添加/修改收款信息 
    async detailPay(params) {
      this.is_submitted = true
      let res = await dealPay(params)
      this.is_submitted = false
      if (res.code === 1) {
        this.$message({
          message: this.$t('message.successfullySubmitted'),
          type: 'success'
        })
        this.proceeds_data_box = 0
        this.getPay()
      }
      console.log(res)
    }
  },
  computed: {
    prohibitClass() {
      return this.pay_data.verify_status == 1 ? 'prohibit' : 'button-me'
    },
    prohibitText() {
      return this.pay_data.verify_status == 0 ? this.$t('message.edit') : this.$t('message.processing')
    }
  },
  components: {
    contentTitle
  }
}